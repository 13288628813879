export const m = {
  sy: 'Home',
  hd: 'Activity',
  ts: 'Explore',
  phb: 'Ranking List',
  glqb: 'Connect Wallet',
  lc: 'Farms',
  bjgrzl: 'Edit Profile',
  dc: 'Log out ',
  wdnft: 'My NFT',
  wsmxzwm: 'Why Choose Us',
  title1: 'Multi - Chain Art Market Enabling Plate',
  info1:
    'FistNFT provides artists with a place to create unique, authentic digital collections in the form of NFT. Realize multi-chain connection layout and support multi-chain NFT assets such as ETH, BSC, and Polygon.',
  title2: 'Support Mainstream Decentralized Storage Solutions',
  info2:
    'Every digital creation on FistNFT is digitally signed by the creator and permanently recorded and verified by mainstream decentralized blockchain technology, ensuring the security of all asset data and ensuring the rarity and uniqueness of digital asset creation.',
  title3: 'Original NFT Economic Enabling Plate',
  info3:
    'FistNFT attaches great importance to the ecological value construction of every NFT project, and the platform creates the NFT economic enabling plate to support high-quality NFT projects to expand the ecology and continuously increase the rights and interests of the holders.',
  ptln: 'Platform Concept',
  szcpylwl: 'Digital Collections Lead To The Future',
  lmkcy: '0 Threshold To Participate In NFT',
  bhczzqy: 'Protecting The Rights And Interests Of Creators',
  jjjzfn: 'Economic Value Empowers NFT',
  kstsb: 'Start Exploring',
  sc: 'market',
  dysstx:
    'Subscribe to the newsletter, not to miss the latest feature version updates, NFT airdrops',
  ndyxdz: 'Your email address',
  dy: 'Subscribe',
  qbfl: 'All categories',
  ysp: 'Works of art',
  scp: 'The collection',
  xnsj: 'The virtual world',
  shiy: 'practical',
  yyue: 'music',
  ty: 'sports',
  ym: 'The domain name',
  cj: 'create',
  tj: 'recommended',
  phb: 'Leaderboard',
  gy: 'About',
  gywm: 'About us',
  bzzx: 'Help center',
  bqsy: 'all rights Reserved',
  yssm: 'Privacy statement',
  fwtk: 'The terms of service',
  shxl: 'GRWA  ',
  shxls: 'Super scarce limited release',
  shxljj:'Hold GRWA to become a global partner of GuiBao metaverse, open the first stop of global RWA, enjoy ecological rights and platform dividends! Participate in global high-quality equity, debt, real estate and other asset allocation, unlock  exclusive rights and interests, and jointly tap Web3 global market potential with GuiBao metaverse, and share industry dividends!',
  djck: 'Click To View',
  wmxl: 'PGNFT CLUB Series 2: ',
  wmxls: 'CIVILIZATION RABBIT',
  wmxljj:'',
  mpxl: 'PGNFT CLUB Series 3： ',
  mpxls: 'MOVIE PANDA',
  mpxljj:'GuiBao metaverse is a new generation of innovative trading platform that combines traditional fintech and disruptive business models to deliver advanced solutions. Created by an experienced global senior team, it aims to bridge the traditional world and Web3 to redefine global financial markets.',
  djzz: 'MINT',
  zzz: 'MINT...',
  fxgz: 'Issue Rules',
  fxmx:
    'Total issuance:20,000 (200 for SSS, 2,800 for SS, 7,000 for S+, 10,000 for S-)',
  gfsm:
    'Holders who hold the PGNFT able to mint with 1 PGNFT on BNB Chain in the real time and all the PGNFT paid by the user will be destroyed immediately.There is no official reservation and no whitelist and the NFT can be viewed in "My collection"  once the minting is completed.',
  zzxz:
    'The minimum casting quantity is 1, and the maximum casting quantity is 50;',
  fxmx2: 'Total issuance: 200,000 (Rarity: none)',
  gfsm2:
    'All supply open to public,all NFT will only be generated upon minted by users. Users can mint at low cost (using fist) on the minting page.The NFT can be viewed in "My collection" once the minting is completed.',
  fxmx3: 'Release quantity: 70000 pieces (no grades respectively) ',
  gfsm3:
    'Distribution rules: For the users who hold FilmNFT card, there is no official reservation or whitelist, and all of them are created by the users themselves. Only for the users who get whitelist by destroying video card, the users who get casting whitelist can cast 1:1 for free on the activity page, which can be viewed in my collection after casting.',
  gxnhd: 'Congratulations',
  qr: 'Confirm',
  zwwtx: 'The brief introduction has not been filled in yet',
  scps: 'Collectible',
  jg: 'result',
  bjgrxx: 'Edit personal Information',
  tx: 'head portrait ',
  txzc:
    'Supports PNG, JPG, and GIF files. The recommended size is 400 x 400px and is less than 1 MB',
  scwj: 'upload files',
  zyfmt: 'Homepage Cover',
  zyfmtxx: 'Support PNG, JPEG, JPG, 1400 x 400px recommended size within 3M',
  yhm: 'username ',
  yhmxx:
    'The value contains 4 to 60 characters, including Digits, underscores (_), and hyphens (-)',
  jj: 'brief introduction ',
  tjlj: 'Add links',
  ndwz: 'Your web site',
  tj: 'Submit',
  tjcg: 'Submit successfully',
  tjsb: 'Submit failure ',
  sccg: 'successfully upload',
  scsb: 'fail to upload ',
  scwjgd: 'Uploading files is too large',
  ljqb: 'Connect your wallet',

  jqqd: 'Stay tuned for',
  az: 'Android',
  fzcg: 'Copy success',
  fzsb: 'Copy the failure',
  hdjs: 'END',

  xhmc: 'Destroy the Film NFT',
  dqqbdz: 'Current Wallet address',
  qsrnft: 'Please enter destroy NFT number',
  nftbh: 'NFT number ',
  qdxh: 'Determine the destruction',
  ljbcqb: 'Link tron wallet',
  sm: 'Explain',
  sminfo:
    'You need to use tron wallet to connect, input the number of Film NFT you want to destroy, after confirmation, we will transfer your Film NFT to the black hole address for destruction, you can participate in the PGNFT CLUB series three activities at fSTnft.com. ',
  qsrzq: 'Please enter the correct NFT number that you have',
  qhcg: 'Destruction successful!',
  bj: 'Edit',
  zylj: 'The home page link',
  xlwb: 'Sina microblog',
  zly: 'is committed to having everyone ',
  bcy: 'own and participate in the NFT world.',
  bdpt:
    'It is an exchange platform for technology preachers, developers, creators, and entrepreneurs.',
  wfhfn: 'Provide incubation and empowerment for FIST NFT development.',
  zdkzzsl: 'Maximum casting quantity:',
  xhsj:
    'FilmNFT destruction time: from 16:00 on June 29 to 16:00 on July 14 for 15 s',
  kqsj: 'Start 1:1 casting time: 16:00, July 16th',
  cyxh:
    'Start 1:1 casting time: July 16, 16:00 NFT processing method involved in destruction: will drive into the wave field chain black hole address, all destroyed',
  in1:
    '1. The user must connect the correct wallet address when destroying the wallet.',

  in2:
    '2. When destroying Film NFT, users should accurately enter the number of Film NFT held in the wallet address. ',

  in3:
    '3. Time of FilmNFT destruction: 16:00 June 30 to 16:00 July 28.  The duration is 28 s. After 28 s, no further destruction operation can be carried out.',

  in4:
    '4. Release a batch of whitelist casting addresses every 7 s, after release can start casting. First casting whitelist address announced and casting start time: 16:00, 6th July.',

  in5:
    '5. The user must retain the tron wallet private key used to destroy Film NFT. In the subsequent casting of PGNFT CLUB Series three MOVIE PANDA NFT on the BSC chain, the wavefield wallet private key that destroys Film NFT connection shall be used to import into the BSC chain and conduct casting.',

  in6:
    '6. NFT processing method involved in the destruction: all the addresses of black holes in the wave field chain will be destroyed.',

  in7:
    'Note: All the addresses that have been destroyed Film NFT in the wave field chain will be collected and whitelisted as BSC addresses. The whitelisted addresses will be publicized in the form of Google Docs. Users can import the wave field wallet private key into the BSC chain to view their corresponding BSC addresses and compare them in Google Docs. Whitelisted addresses are published every 7 s.',

  sthb: 'Ecological Partner',
  index: 'Home',
  my: 'My',
  zrjl: "Transfer record",
  zcjl: 'Roll-out record',
  zzs: 'Transfer',
  yqjl: "Invitation record",
  yhdz: 'User address',
  qx: "Cancel",
  qd: "Confirm",
  qtxdz: "Please fill in the address",
  qsyyqrdz: "Please enter the inviter's wallet address or invitation code",
  klyx: "Consider",
  qzz: "Buy",
  zz: "Mint",
  zwsj: "No Data",
  nomore: 'No more.',
  loading: 'Loading...',
  yynum: 'Owned:',
  zhuzao: "Cast",
  hecheng: "Craft",
  mei: "Sheet",
  faxingguize: "Issuance rules",
  jiazhi: "value",
  yizhuzao: "Already cast",
  weizhuzao: "Uncast",
  leijishifang: "Cumulative release",
  zhuzaoguize: "Casting rule",
  zhu: "Note*",
  zhuzaoguize1: " Bronze NFT can cast (release) GBB (other synthetic NFT cannot participate in casting)",
  zhuzaoguize2: "The cast NFT cannot be transferred;",
  zhuzaoguize3: "Please fill in the invitation code when casting, you can start casting immediately after filling in;",
  zhuzaoguize4: "Once the casting begins, it cannot be terminated, and the NFT is automatically destroyed after the casting is completed.",
  wodezhuzao: "My casting",
  lingqujilu: "Claim record",
  zhuzaoshijian: "Casting time",
  zhuzaozhouqi: "Casting cycle",
  shifangliang: "Release volume",
  yilingqu: "Claimed",
  dailingqu: "Waiting",
  liqu: "Claim",
  shengyu: "Remaining",
  xuanzhehechengshuliang: "Selective synthesis quantity",
  yixuan: "Selected:",
  hechengguize: "Composition rule",
  lijihecheng: "Immediate synthesis",
  v1nft: "V1",
  v2nft: "V2",
  v3nft: "V3",
  v4nft: "V4",
  goumai: "Buy",
  heyuedizhi: "Contract address",
  fasong: "Send",
  cangping: "NFTs",
  jiaoyijilu: "Send history",
  shoukuandizhi: "Address",
  jieshoudizhi: "Receiving address",
  qingshurushoukuandizhi: "Please enter the collection address",
  queren: "Confirm",
  beiyaoqingrendizhi: "Address of invitee",
  zhuzaoshulian: "Cast quantity",
  fasongchenggong: "send successfully",
  fasongshibai: "fail to send",
  lingquchenggong: "received successfully",
  lingqushibai: "Claim failure",
  leijilingqu: "Cumulative claim",
  lingqushuliang: "Quantity claimed",
  lingqushijian: "receive time",
  zhuzaoqingshurushangjidizhi: "Foundry please enter the upper address",
  zhuzaochenggong: "Casting success",
  zhhuzaoshibai: "Casting failure",
  guanyunt: "About GBB",
  guanyu1: "1.GBB is the core asset of the platform issued based on the NEXT ecological development and generated by GRWA NFT. Holders can participate in the allocation of global high-quality equity, debt, real estate and other assets to share the potential and returns of the global market.",
  guanyu2:"2. The amount of GBB released by GRWA NFT casting is 30% of the total GBB, the casting cycle is 200 s, and the daily casting amount is 50 pieces/.",
  guanyu3: "3. The GBB released by the mining part of the mining pool is 70% of the total GBB, the mining amount in the first year is 20%, and the mining amount in subsequent years decreases according to 80% of the first year.",
  yqzx: "Invitation center",
  gfyqm: "Official invitation code",
  wdyqm: "My invitation code",
  lijizhuzao: "Instant casting",
  max: "MAX",
  bukibangdingzijidizhi: "Cannot bind your own wallet address",
  day: "Days",
  hechengzhong: "Crafting...",
  goumaishibai: "Purchase failure",
  shouquanshibai: "authorization failed ",
  nindeubugouyizhifu: "Insufficient USDT in your wallet has been paid"



}
