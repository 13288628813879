<template>
  <div class="main" :class="show ? 'h5' : ''">
        <!-- <mtop /> -->
      <!-- <div class="stop">
        <div @click="toPage('/User')">
            <img src="../assets/img/back.png" alt="" srcset="" class="ico">
        </div>
        <div class="title">{{$t('m.fasong')}}</div>
    </div>   -->
    <div class="sheader" >
        <div class="haerbox">
        <div class="nftimg">
        <img src="../assets/logo.png" alt="" srcset="" class="her">
        </div>
        <div>
        <div class="name">GRWA</div>
        <div class="dec">BNB Chain·BEP-1155</div>
        </div>
        </div>   
          <div class="haerbox" v-if="acdata">
        <div class="nftimg">
        <img :src="acdata.img" alt="" srcset="" class="nft">
        </div>
        <div class="f14">
        <div class="name">{{acdata.name}}</div>
        <div class="dec">{{mynum}}{{ $t('m.mei') }}</div>
        </div>
         <van-stepper v-model="buynum" :max="mynum" input-width="50px" :min="0"  button-size="32px"  class="mint" />
   
        </div>      
    </div>
    
    <div class="navtab">
         <div class="stitle">
        {{$t('m.jieshoudizhi')}}
      </div>
    </div>    
    <div class="line"></div>
    <div class="content">   
        <div class="box">
        <input type="text" class="input" v-model="address" :placeholder="$t('m.qingshurushoukuandizhi')">
        </div>
    </div>
 <div class="bottomfilex">
     <van-button
          :loading="loading||uniLoading"
          :disabled="cansend"
          type="primary"
          @click="toSend"
          class="btn"
          loading-text="Loading..."
        >
         {{$t('m.queren')}}</van-button
        >
    </div>
    <Tranfer
      :show="tshow"
      :data="plandata"
      @tshow="
        (val) => {
          tshow = val;
        }"
      @updata="
        (val) => {
          tshow = false;
          if(val){this.getmyNum()}
        }
      "
    />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
import {walletOfOwner,ImgV1,ImgV2,ImgV3,ImgV4,getChildren,SendNft,sendTrans,getNoce  } from "@/api/Public";
export default {
  data() {
    return {
      email: "",
      show: false,
      active: 0,
        list: [[],[],[]],
        page: 1,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
      nftImg: ImgV1,
      cur: 0,
      buynum: 0,
      address: '',
      ids:0,
      uniLoading:false,
      base: [
           { id:0,
              name:  this.$t('m.v1nft'),
          img: ImgV1,
              num:0
            },
            { id:1,
              name:   this.$t('m.v2nft'),
              img: ImgV2,
              num:0
            },
          {id:2,
              name:  this.$t('m.v3nft'),
            img: ImgV3,
              num:0
            },
          {id:3,
              name: this.$t('m.v4nft'),
            img: ImgV4,
              num:0
          }
      ],
      acdata: '',
         buynum:0,
    };
    },
    components: {
    mtop,
        mbottom,
    Tranfer
  },
  created() {
    this.ids=this.$route.query.id||0
    this.acdata = this.base[this.$route.query.id||0]
       console.log(this.acdata);
      this.getmyNum()
     
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    window.nftSends=(info)=>{
      this.handleSends(info)
    }
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },
    computed: {
      cansend() {
        if (this.address && this.buynum > 0) {
          if (this.address.toUpperCase() == this.$store.getters.userWallet.toUpperCase()) { 
            return true
          } else {
            return false;
          }        
      } else {
        return true;
      }
    },
  },
  methods: {
    async handleSends(info){
      this.uniLoading=true
     const res= await sendTrans(info)
     if (res.success) {
              this.uprecode(this.buynum, this.address, this.ids)
              this.getmyNum()
              this.$notify({ message: this.$i18n.t("m.fasongchenggong"), type: "success" });
              this.address = ''
              this.buynum = ''
            } else {
              this.$notify(this.$i18n.t("m.fasongshibai"))
            }
            this.uniLoading=false
    },
   async toSend() { 
      sessionStorage.setItem('type',5)
      let resp= await getNoce(this.$store.getters.userWallet)
     if(resp.success){
      uni.postMessage({
        data: {
          noce: resp.res
        
        }
      })
     }
      if (this.address.indexOf('0x') != -1) {
        if (this.buynum > 0 && this.address) {
          this.loading = true
          SendNft(this.ids, this.address, this.buynum, this.$store.getters.userWallet).then(res => {
            this.loading = false
            if (res) {
              this.uprecode(this.buynum, this.address, this.ids)
              this.getmyNum()
              this.$notify({ message: this.$i18n.t("m.fasongchenggong"), type: "success" });
              this.address = ''
              this.buynum = ''
            } else {
              // this.$notify(this.$i18n.t("m.fasongshibai"))
            }
          })
        }
      } else {
        this.$notify(this.$i18n.t("m.qingshurushoukuandizhi"))
      }
    },
     uprecode(num,address,ids) { 
      let timeStamp = new Date().getTime()
     let time=  new Date(timeStamp).toLocaleString();
      let list = JSON.parse(localStorage.getItem('NFT-'+this.$store.getters.userWallet))||[]
      let obj = {num:num,time:time,address:address,id:ids}
      list.unshift(obj)
      localStorage.setItem('NFT-'+this.$store.getters.userWallet,JSON.stringify(list))
    },
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
        getmyNum() { 
            walletOfOwner(this.$store.getters.userWallet,this.ids).then(res => {
                this.mynum = res
                this.onLoad()
                // this.list[0].length=this.mynum
            })
        },
        getNum(address, i) { 
            walletOfOwner(address).then(res => {
                this.$set(this.list[this.active][i],'address',address)
                this.$set(this.list[this.active][i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 

        },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
                setTimeout(() => {
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
        },
        onLoad() { 
               this.loading = false;
            this.finished = true;
            if (this.active == 2) {
              this.togetChaildren()
            }
         this.list = [[], [], []]
         this.refreshing=false
        },
         onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
        togetChaildren() { 
            this.list[2]=[]
            getChildren(this.$store.getters.userWallet, this.type).then(res => {  
                  if (res.length > 0) {                   
                      res.map((el, i) => {
                         this.list[2].push({address:'',num:''})
                          this.getNum(el, i)
                      })
                    }
                })
        },
        tochanger(e) {
            this.active = e
            if (e == 2) {
                 this.loading = true;
                 setTimeout(() => {
                    this.togetChaildren()          
                 }, 100);
                   
            }
         },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   padding-top:50px;
   .sheader{
    width: 686px;
height: 280px;
border-radius: 8px;
border: 2px solid #EEEEEE;
box-sizing: border-box;
      padding: 0 32px;
      margin: 0 auto;
    .haerbox{
          width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;      
        margin-top: 28px;
      .nftimg{
        position: relative;
        .her{
             width: 53px;
            height: 60px;
        }
        .nft{
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
        .num{
            position: absolute;
            right: -60px;
            bottom: 0;
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }
      }
      .f14{
        flex: 1;
      }
      .name{
        font-size: 32px;
font-weight: 500;
color: #181818;
line-height: 44px;
text-align: left;
margin-left: 20px;
      }
      .dec{
        font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
text-align: left;
margin-left: 20px;
      }
      .snum{
        width: 204px;
height: 60px;
border-radius: 8px;
border: 2px solid #009270;
font-size: 32px;
font-weight: 400;
color: #009270;
line-height: 60px;
margin: 10px auto 48px;
      }
        .btn{
      width: 336px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
    }
  
   }
   .bg{
    background: #F7F7F7;
   }
   .navtab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;    
        border-radius: 48px 48px 0 0;
    background: #ffffff;
     .stitle{
            text-align: left;
      font-size: 28px;
font-weight: 600;
color: #333333;
line-height: 34px;
margin: 18px 32px 22px;
    }
    .item{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
flex: 1;
line-height: 40px;
padding-bottom: 10px;
    }
    .act{
        color: #181818;
        position: relative;
    }
    .act::after{
        position: absolute;
        content: '';
        bottom: -1px;
        width: 48px;
height: 4px;
background: #009270;
left: 50%;
transform: translateX(-50%);
    }
   }
   .line{
    width: 100%;
    height: 1px;
background: #F7F7F7;
   }
    .stop{
        position: relative;
        width: 100%;
        height: 100px;
        .ico{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-50%);
        }
        .title{
            text-align: center;
            font-size: 32px;
font-weight: 400;
color: #333333;
line-height: 100px;
        }
    }
.content{

.box{
       width: 686px;
height: 132px;
background: #F7F7F7;
border-radius: 8px;
margin: 0 auto; 
padding: 32px;
box-sizing: border-box; 
.input{
    border: none;
    height: 100% ;
    width: 100%;
    background: transparent;
    color: #333333;
    font-size: 28px;
    font-weight: 400;
} 
}
}

 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
    
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
    .rule {
    width: 100%;
    padding: 50px 32px 60px;
    box-sizing: border-box;
    .card {
      width: auto;
      margin: 0 auto;
      text-align: left;
      font-size: 26px;
      .p {
        width: auto;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 42px;
      }
    }
  }
  .bottomfilex{
      position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
       background: #ffffff;
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.25);
backdrop-filter: blur(10px);
    padding: 32px 0;
    width: 100%;
    .btn{
         width: 686px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
  }
}
</style>