<template>
  <div class="footer">
    <div class="mbottom" :class="show ? 'h5' : ''">
      <div class=" sitem" @click="toPage('/')">
        <img :src="active == '/' ? homes : home" alt="" srcset="" class="icon">
        <div :class="active == '/' ? 'act' : 'txt'">{{ $t('m.index') }}</div>
      </div>
      <div class=" sitem" @click="toPage('/Cast')">
        <img :src="active == '/Cast' ? zhuzaos : zhuzao" alt="" srcset="" class="icon">
        <div :class="active == '/Cast' ? 'act' : 'txt'">{{ $t('m.zhuzao') }}</div>
      </div>
      
      <div class=" sitem" @click="toPage('/Craft')">
        <img :src="active == '/Craft' ? hecs : hec" alt="" srcset="" class="icon">
        <div :class="active == '/Craft' ? 'act' : 'txt'">{{ $t('m.hecheng') }}</div>
      </div>
      <div class=" sitem" @click="toPage('/Node')">
        <img :src="active == '/Node' ? node1 : node2" alt="" srcset="" class="icon">
        <div :class="active == '/Node' ? 'act' : 'txt'">{{ $t('m.nodeText') }}</div>

      </div>
      <div class=" sitem" @click="toPage('/User')">
        <img :src="active == '/User' ? mys : my" alt="" srcset="" class="icon">
        <div :class="active == '/User' ? 'act' : 'txt'">{{ $t('m.my') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      show: false,
      active: '/',
      home: require('../assets/img/home.png'),
      homes: require('../assets/img/homes.png'),
      my: require('../assets/img/my.png'),
      mys: require('../assets/img/mys.png'),
      zhuzao: require('../assets/img/zhuzao.png'),
      zhuzaos: require('../assets/img/zhuzaos.png'),
      hec: require('../assets/img/hecheng.png'),
      hecs: require('../assets/img/hechengs.png'),
      node1:require('../assets/img/node_icon_yes.png'),
      node2:require('../assets/img/node_icon_no.png'),
    };
  },
  created() {
    this.active = this.$route.path;
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$notify({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";

.footer {
  margin-top: 100px;
  background: #ffffff;
}

.mbottom {
  background: #ffffff;
  background-size: 100% 100%;
  // min-height: 498px;
  // border-top: 1px solid #BFBFBF;
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 98px;
  position: fixed;
  bottom: 0;
  z-index: 101;
  box-shadow: 0 -0.1rem 0.32rem rgba(20, 20, 22, .03);

  .sitem {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 68px;
    padding-top: 5px;

    .icon {
      width: 56px;
      height: 56px;
    }

    .txt {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }

    .act {
      font-size: 20px;
      font-weight: 400;
      color: #1B9070;
      line-height: 28px;
    }
  }

}
</style>