export const m = {
  // 顶部
  sy: '首页',
  hd: '活动',
  ts: '探索',
  phb: '排行榜',
  lc: '龙场',
  glqb: '关联钱包',
  bjgrzl: '编辑个人资料',
  dc: '登出',
  wdnft: '我的NFT',
  // 首页
  wsmxzwm: '为什么选择我们',
  title1: '多链艺术市场赋能板块',
  info1: '   FistNFT为艺术家提供了一个以 NFT形式创作独特、真实的数字收藏品的场所。实现多链衔接布局，支持ETH/BSC/Polygon等多链NFT资产。',
  title2: '支持主流去中心化存储方案',
  info2: 'FistNFT上的每一个数字创作都由创作者进行数字签名，并通过主流去中心化区块链技术永久记录和验证，所有资产数据安全有保障，确保数字资产创作的稀缺性和独特性。',
  title3: '独创NFT经济赋能板块',
  info3: 'FistNFT重视每一个NFT项目的生态价值建设，平台独创NFT经济赋能板块，支持优质NFT项目扩展生态，不断增加持有者的权益',
  ptln: '平台理念',
  szcpylwl: '数字藏品引领未来',
  lmkcy: '0门槛参与NFT',
  bhczzqy: '保护创作者权益',
  jjjzfn: '经济价值赋能NFT',
  kstsb: '开始探索吧',
  sc: '市场',
  // 底部
  dysstx: '订阅时事通讯,不错过最新功能版本更新、NFT空投',
  ndyxdz: '您的邮箱地址',
  dy: '订阅',
  qbfl: '全部分类',
  ysp: '艺术品',
  scp: '收藏品',
  xnsj: '虚拟世界',
  shiy: '实用',
  yyue: '音乐',
  ty: '体育',
  ym: '域名',
  cj: '创建',
  tj: '推荐',
  phb: '排行榜',
  gy: '关于',
  gywm: '关于我们',
  bzzx: '帮助中心',
  bqsy: '版权所有',
  yssm: '隐私声明',
  fwtk: '服务条款',
  // 活动
  shxl: 'GRWA',
  shxls: '  超稀缺限量发售',
  shxljj: '成为世界数字中央银行全球合伙人，开启全球RWA第一站，享受生态权益和平台红利！参与全球优质股权，债权，房地产等资产配置，解锁专属权益，与世界数字中央银行共同挖掘Web3全球市场潜力，共享行业红利！',
  mpxljj: 'GRWA新一代创新型交易平台瑰宝元宇宙，融合传统金融科技和颠覆性商业模式，提供先进解决方案。由经验丰富的全球资深团队创建，旨在构建传统世界和Web3的桥梁，重新定义全球金融市场。',
  wmxljj: '世界数字中央银行——基于最先进的区块链数据库和人工智能高效管理以及Web3.0区块链应用技术的加密银行。GRWA是其利用区块链技术力量來保护和增长客戶资产又一重磅产品。GRWA发行价格2000USDT/张，总发行量30000张，售完不再增发。GBKGen NFT，可合成，铸造，转让。是获取平台核心资产GDA的唯一方式。',
  djck: '点击查看',
  wmxl: 'PGNFT CLUB系列二: ',
  wmxls: 'CIVILIZATION RABBIT 文明系列',
  mpxl: 'PGNFT CLUB 系列三：',
  mpxls: 'MOVIE PANDA',
  djzz: '立即购买',
  zzz: '支付中...',
  fxgz: 'GRWA发行规则',
  fxmx: '发行数量：30000枚',
  gfsm: '面向持有BSC链上的PGNFT的用户，官方无预留无白名单，全部由用户自己实时铸造产生。用户可在活动铸造页面，通过支付1 PGNFT直接铸造，用户所支付的PGNFT将全部销毁。铸造完成后可在我的收藏品中查看。',
  zzxz: '价格：2000U',
  fxmx2: '发行数量：200000枚（无等级分别）',
  gfsm2: '面向全部用户，全部由用户实时铸造产生。用户可在活动铸造页面以低成本进行铸造，铸造完成后可在我的收藏品中查看。 ',
  fxmx3: '发行数量：70000枚（无等级分别）',
  gfsm3: '发行规则：面向持有FilmNFT影视卡的用户，官方无预留无白名单，全部由用户自己铸造产生。仅限通过销毁影视卡获得白名单的用户，获得铸造白名单的用户可在活动页面进行1:1免费铸造，铸造完成后可在我的收藏品中查看。',
  gxnhd: '恭喜你获得',
  qr: '确认',
  // 我的NFT
  zwwtx: '暂未填写简介',
  scps: '收藏品',
  jg: '结果',
  // 个人信息
  bjgrxx: '编辑个人信息',
  tx: '头像',
  txzc: '支持PNG、JPG、GIF，推荐尺寸400 × 400px，大小在1M以内',
  scwj: '上传文件',
  zyfmt: '主页封面图',
  zyfmtxx: '支持PNG, JPEG, JPG，推荐尺寸1400 × 400px，大小在3M以内',
  yhm: '用户名',
  yhmxx: '4-60个字符，支持中英文、数字、下划线和减号',
  jj: '简介',
  tjlj: '添加链接',
  ndwz: '您的网站',
  tj: '提交',
  tjcg: '提交成功',
  tjsb: '提交失败 ',
  sccg: '上传成功',
  scsb: '上传失败',
  scwjgd: '上传文件过大',
  ljqb: '连接您的钱包',





  jqqd: '暂未开始',
  az: '安卓',
  fzcg: '复制成功',
  fzsb: '复制失败',

  xhmc: '销毁Film NFT',
  dqqbdz: '当前钱包地址',
  qsrnft: '请输入销毁NFT编号',
  nftbh: 'NFT编号',
  qdxh: '确定销毁',
  ljbcqb: '链接波场钱包',
  sm: '用户操作注意事项',
  sminfo: '需要使用波场钱包连接，输入您要销毁的Film NFT数量，确认后我们会将您的Film NFT转入黑洞地址进行销毁，后续您可在fstnft.com活动参与PGNFT CLUB系列三活动。',
  qsrzq: '请输入您拥有的正确的NFT编号',
  qhcg: '销毁成功！',
  bj: '编辑',
  zylj: '主页链接',
  xlwb: '新浪微博',
  hdjs: '铸造已结束',

  zly: '致力于让每一个人拥有',
  bcy: '并参与到NFT世界中',
  bdpt: '技术布道者、开发者、创造者和创业者的交流平台',
  wfhfn: '为FIST NFT开发提供孵化和赋能',
  zdkzzsl: '最大可铸造的数量：',
  xhsj: '波场FilmNFT销毁时间：6月29日16点到7月14日16点  为期15',
  kqsj: '开启1:1铸造时间：7月16日16点',
  cyxh: '参与销毁的NFT处理方式：将打入波场链黑洞地址，全部销毁',
  in1: '1.用户在进行销毁时，需连接正确的钱包地址。',
  
  in2: '2.用户在销毁Film NFT时，需准确输入钱包地址中持有的Film NFT的编号。',
  
  in3: '3.波场FilmNFT销毁时间：6月30日16:00到7月28日16:00。为期28，超过28后，不可再进行销毁操作。',
  
  in4: '4.每7释放一批白名单铸造地址，释放后方可开启铸造。首次铸造白名单地址公布并开启铸造时间：7月6日16:00',
  
  in5: '5.用户需保留好销毁Film NFT使用的波场钱包私钥。后续在BSC链上铸造PGNFT CLUB系列三MOVIE PANDA NFT时，需使用销毁Film NFT连接的波场钱包私钥导入BSC链，并进行铸造。',
  
  in6: '6.参与销毁的NFT处理方式：将打入波场链黑洞地址，全部销毁。',
  
  in7: '注：所有在波场链进行过销毁Film NFT操作的地址，链上会收集对应BSC地址并列为白名单地址，白名单地址会以谷歌文档的形式公示，用户可自行用波场钱包私钥导入BSC链查看自己对应的BSC地址并在谷歌文档进行对照。每7公布一次白名单地址。',
  sthb: '生态伙伴',
  index: '首页',
  my: '我的',
  zrjl: "转入记录",
  zcjl: '转出记录',
  zzs: '转账',
  yqjl: "邀请记录",
  yhdz: '用户地址',
  qx: "取消",
  qd: "确定",
  qtxdz: "请填写地址",
  qsyyqrdz: "请输入邀请人钱包地址或邀请码",
  klyx: " 考虑一下",
  qzz: "购买",
  zz: "铸造",
  zwsj: '暂无数据',
  nomore: '没有更多了',
  loading: '加载中...',
  yynum: '拥有数量：',
  zhuzao: "铸造",
  hecheng: "合成",
  mei: "张",
  faxingguize: "发行规则",
  jiazhi: "价值",
  yizhuzao: "已铸造",
  weizhuzao: "未铸造",
  leijishifang: "累计释放",
  zhuzaoguize: "铸造规则",
  zhu: "注*",
  zhuzaoguize1: " 青铜NFT可铸造（释放）GDA（其他合成NFT不可参与铸造）；",
  zhuzaoguize2: "已铸造的NFT无法进行转让；",
  zhuzaoguize3: "铸造时请填写邀请码，填写完成后可立即开始铸造；",
  zhuzaoguize4: "铸造一旦开始不能终止，铸造完成后NFT自动销毁。",
  wodezhuzao: "我的铸造",
  lingqujilu: "领取记录",
  zhuzaoshijian: "铸造时间",
  zhuzaozhouqi: "铸造周期",
  shifangliang: "释放量",
  yilingqu: "已领取",
  dailingqu: "待领取",
  liqu: "领取",
  shengyu: "剩余",
  xuanzhehechengshuliang: "选择合成数量",
  yixuan: "已选：",
  hechengguize: "合成规则",
  lijihecheng: "立即合成",
  v1nft: "青铜",
  v2nft: "白银",
  v3nft: "黄金",
  v4nft: "钻石",
  goumai: "购买",
  heyuedizhi: "合约地址",
  fasong: "发送",
  cangping: "NFTs",
  jiaoyijilu: "发送历史",
  shoukuandizhi: "收款地址",
  jieshoudizhi: "接收地址",
  qingshurushoukuandizhi: "请输入收款地址",
  queren: "确认",
  beiyaoqingrendizhi: "被邀请人地址",
  zhuzaoshulian: "铸造数量",
  fasongchenggong: "发送成功",
  fasongshibai: "发送失败",
  lingquchenggong: "领取成功",
  lingqushibai: "领取失败",
  leijilingqu: "累计领取",
  lingqushuliang: "领取数量",
  lingqushijian: "领取时间",
  zhuzaoqingshurushangjidizhi: "铸造请输入上级地址",
  zhuzaochenggong: "铸造成功",
  zhhuzaoshibai: "铸造失败",
  guanyunt: "关于GRWA",
  guanyu1: " 1.GRWA是基于瑰宝元宇宙发行出的平台核心资产，由GRWA NFT铸造产生，持有者可参与全球优质股权，债权、房地产等资产配置、共享全球市场的潜力和回报",
  guanyu2:"2.GRWA NFT铸造释放GRWA的量为GRWA总量的30%，铸造周期为200天，每日铸造量为50枚",
  guanyu3: "3.矿池挖矿部分释放的GRWA为GRWA总量的70%，第一年挖矿量为20%，后续年份的挖矿量按照头一年的80%递减",
  yqzx: "邀请中心",
  gfyqm: "官方邀请码",
  wdyqm: "我的邀请码",
  lijizhuzao: "立即铸造",
  max: "全部",
  bukibangdingzijidizhi: "不可绑定自己的钱包地址",
  day: "天",
  hechengzhong: "合成中",
  goumaishibai: "购买失败",
  shouquanshibai: "授权失败",
  nindeubugouyizhifu:"您钱包的USDT不足已支付",
  nodeText:'节点',
  nodeText1:'节点机制',
  nodeText2:'抢先拥有节点，畅享平台尊贵权益！节点可自由转让，持有即享平台多重优质RWA项目空投与分红，涵盖项目手续费分红等专属权益，尽显尊贵特权！立即参与，解锁更多惊喜！',
  userText1:'世界数字中央银行——基于最先进的区块链数据库和人工智能高效管理以及Web3.0区块链应用技术的加密银行。GRWA是其利用区块链技术力量來保护和增长客戶资产又一重磅产品。',
  userText2:'GRWA发行价格2000USDT/张，总发行量30000张，售完不再增发。GBKGen NFT，可合成，铸造，转让。是获取平台核心资产GDA的唯一方式。',
  
  
}