<template>
  <div class="main" :class="show ? 'h5' : ''">
        <mtop />
    <div class="sheader" >
        <div class="haerbox">
        <div class="nftimg">
        <img src="../assets/img/ulogo.png" alt="" srcset="" class="nft">
        </div>
        <div class="name">GRWA</div>
        <!-- <div class="dec">{{$t('m.shxljj')}}</div> -->
  <div class="dec">{{$t('m.userText1')}}</div>
    <div class="dec">{{$t('m.userText2')}}</div>
        <div class="contract">
         {{$t('m.heyuedizhi')}}：{{ contAddress|yincang }} <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="contAddress"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
           <img src="../assets/img/fz2.png" alt="" class="ic" srcset="">
          </button>
        </div>
        <div class="btnsroup">
        <div class="btn" @click="toPage('/')">{{$t('m.goumai')}}</div>
        <div class="btns" @click="toPage('/Send')">{{$t('m.fasong')}}</div>
        </div>
        </div>        
    </div>
    <van-sticky :offset-top="0" class="bg">
    <div class="navtab">
        <div class="item" :class="active==0?'act':''" @click="tochanger(0)">{{$t('m.cangping')}}</div>
         <div class="item" :class="active==1?'act':''" @click="tochanger(1)">{{$t('m.jiaoyijilu')}}</div>
    </div>    
    <div class="line"></div>
    </van-sticky>
    <div class="content">
        <div class="slist" v-if="active==0">
          <div class="sitem" v-for="(item,i) in base" :key="i" @click="toSend('/Send',i)">
             <img :src="item.img" alt="" srcset="" class="nft">
             <div class="bottom">
              <div class="left">{{item.name}}</div>
              <div class="right">x{{item.num}}</div>
             </div>
          </div>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh"  
        v-if="active==1"
        :loosing-text="$t('m.loading')"
        :loading-text="$t('m.loading')">
        <van-list
          v-model="loading"
          :finished="finished"
          :loading-text="$t('m.loading')"
          @load="onLoad"
          :finished-text="$t('m.nomore')"
          style="min-height: 80vh"          
        >
          <div
            v-for="(item, i) in list"
            :key="i"
            class=""
          >
          <div class="item">
            <div class="left">
                <img :src="nftImg" alt="" srcset="" class="nft" v-if="item.id==0">
                 <img :src="nftImg2" alt="" srcset="" class="nft" v-if="item.id==1">
                  <img :src="nftImg3" alt="" srcset="" class="nft" v-if="item.id==2">
                   <img :src="nftImg4" alt="" srcset="" class="nft" v-if="item.id==3">
            <div class="name">
              <div class="title">{{item.id==0?$t('m.v1nft'):''}}
                {{item.id==1?$t('m.v2nft'):''}}
                {{item.id==2?$t('m.v3nft'):''}}
                {{item.id==3?$t('m.v4nft'):''}}
                
                X{{ item.num }}</div>
              <div class="time">
                {{item.time}}
              </div>
              <div class="address">{{$t('m.shoukuandizhi')}}:{{item.address|yincang}}</div>
              </div>
            </div>

            <div class="right">
               <!-- <img src="../assets/img/icon-jiesou.png" alt="" class="ic" srcset="" v-if="item.from.toUpperCase()==userWallet.toUpperCase()"> -->
                <img src="../assets/img/icon-fasong.png" alt="" class="ic" srcset="" >
            </div>
            </div>
            
          </div>
          <div v-if="list.length == 0" class="noData">
            <!-- <div class="ntxt">{{$t('m.zwsj')}}</div> -->

          </div>
          <div class="kongbai"></div>
        </van-list>
      </van-pull-refresh>

    </div>
    <Tranfer
      :show="tshow"
      :data="plandata"
      @tshow="
        (val) => {
          tshow = val;
        }"
      @updata="
        (val) => {
          tshow = false;
          if(val){this.getmyNum()}
        }
      "
    />
     <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
import {walletOfOwner,ImgV1,ImgV2,ImgV3,ImgV4,getChildren,ADDRESS_NFT,SKye } from "@/api/Public";
export default {
  data() {
    return {
      email: "",
      show: false,
      active: 0,
        list: [],
        page: 1,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
      nftImg: ImgV1,
       nftImg2: ImgV2,
      nftImg3: ImgV3,
         nftImg4: ImgV3,
      contAddress: ADDRESS_NFT,
       userWallet:this.$store.getters.userWallet,
      base: [
        { 
          img: ImgV1,
          name: this.$t('m.v1nft'),
          num: 0
        },
          {
          img: ImgV2,
          name:this.$t('m.v2nft'),
          num: 0
        },
          {
          img: ImgV3,
          name:this.$t('m.v3nft'),
          num: 0
        },
          {
          img: ImgV4,
          name:this.$t('m.v4nft'),
          num:0}
        ],
      cur: 0,
        address:''
    };
    },
    components: {
    mtop,
        mbottom,
    Tranfer
  },
  created() {
       this.page=1  
           let i = setInterval(() => {
      if ((typeof window.ethereum) !== 'undefined') {   
      this.getmyNum();
        clearInterval(i)
    }
}, 1000); 
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },
    methods: {
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
      getmyNum() { 
        this.base.map((el, i) => {
             walletOfOwner(this.$store.getters.userWallet,i).then(res => {
                this.$set(this.base[i],'num',res)
                this.onLoad()
            })
          })
           
        },
        getNum(address, i) { 
            walletOfOwner(address).then(res => {
                this.$set(this.list[this.active][i],'address',address)
                this.$set(this.list[this.active][i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 

        },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
                setTimeout(() => {
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
      },
      toSend(e,i) { 
 this.$router.push({ path: e,query:{id:i}});
      },
      onLoad() {  
          if (this.active == 1) {
              this.loading=true
              this.togetChaildren()
            } else {      
                    this.loading = false;
            this.finished = true;        
         this.refreshing=false
            }
        },
      onRefresh() {
          this.page=1  
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
      togetChaildren() {    
            let list = localStorage.getItem('NFT-'+this.$store.getters.userWallet)
        this.list = list ? JSON.parse(list) : []    
        this.finished = true;    
              this.loading = false;
              this.refreshing=false
          // this.$nextTick(function () {
          //   if (res.status=='1') {
          //     this.loading = false;
          //     this.refreshing=false
          //     if (this.page > 1) {
          //       this.list = [...this.list, ...res.result];
          //     } else {
          //       this.list = res.result;
          //     }
          //     console.log(res.result);
          //     if (res.result.length == 10000) {
          //       this.page++;
          //       console.log(this.page);
          //     } else {
          //       this.finished = true;
          //     }
          //   } else {
          //     this.finished = true;
          //   }
          // });
        },
        tochanger(e) {
            this.active = e
          if (e == 1) {
                 setTimeout(() => {
                    this.onRefresh()          
                 }, 100);
                   
            }
         },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e});
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   .sheader{
    width: 100%;
     background: url("../assets/img/bg.png") no-repeat top center;
    background-size: cover;
    .haerbox{
          width: 100%;
      height: 100%;
      min-height: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 109px;
      padding-bottom: 32px;
      .nftimg{
        position: relative;
        .nft{
            width: 100%;
            height: 130px;
        }
       
        .num{
            position: absolute;
            right: -60px;
            bottom: 0;
            font-size: 28px;
font-weight: 500;
color: #ffffff;
line-height: 40px;
letter-spacing: 2px;
        }
      }
      .name{
        font-size: 32px;
font-weight: 500;
color: #ffffff;
line-height: 44px;
margin: 14px 0;
      }
       .dec{
          font-size: 24px;
font-weight: 400;
color: #ffffff;
line-height: 34px;
margin: 0px 24px 10px;
text-align: left;
        }
        .contract{
          font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #ffffff;
line-height: 34px;
display: flex;
align-items: center;
justify-content: center;
margin-top:12px;
 .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
        }
        .btnsroup{
          display: flex;
          align-items: center;
          .btns{

           margin: 20px;
      width: 196px;
height: 60px;
background: transparent;
border-radius: 4px;
border: 1px solid #ffffff;  
font-size: 32px;
font-weight: 500;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
          }
        }
        .btn{
          margin: 20px;
      width: 196px;
height: 60px;
background: #ffffff;
border-radius: 4px;
border: 1px solid #ffffff;  
font-size: 32px;
font-weight: 500;
color: #009270;
display: flex;
align-items: center;
justify-content: center;
    }
    }
  
   }
   .bg{
    background: #F7F7F7;
   }
   .navtab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;    
        // border-radius: 48px 48px 0 0;
    background: #ffffff;
    .item{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
flex: 1;
line-height: 40px;
padding-bottom: 10px;
    }
    .act{
        color: #181818;
        position: relative;
    }
    .act::after{
        position: absolute;
        content: '';
        bottom: -1px;
        width: 48px;
height: 4px;
background: #009270;
left: 50%;
transform: translateX(-50%);
    }
   }
   .line{
    width: 100%;
    height: 1px;
background: #F7F7F7;
   }
.content{
    // height: calc(100vh - 200px);
    overflow-y: auto;
    .slist{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 100px;
      margin-top: 32px;
      .sitem{
        position: relative;
        width: 330px;
        height: 326px;
        background: #D8D8D8;
        border-radius: 16px;
        margin: 13px;
            display: flex;
    align-items: center;
    justify-content: center;
        .nft{
          width: 70%;
          height: 70%;
          border-radius: 16px;
        }
        .bottom{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          position: absolute;
bottom: 10px;
width: 100%;
padding: 20px;
box-sizing: border-box;
          .left{
            min-width: 92px;
height: 42px;
background: #0000007a;
border-radius: 28px;
// opacity: 0.5;
padding:0 20px;
font-size: 24px;
font-weight: 400;
color: #FFFFFF;
line-height: 34px;
letter-spacing: 4px;
display: flex;
align-items: center;
justify-content: center;
          }
          .right{
            font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 34px;
text-shadow: 0px 2px 4px rgba(0,0,0,0.5);

          }
        }
      }
    }
    .item{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #F0F0F0;
        .left{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .nft{
                width: 100px ;
                height: 100px;
                margin: 0 20px;
                border-radius: 8px;
            }
            .title{
                font-size: 32px;
font-weight: 500;
color: #333333;
line-height: 44px;
text-align: left;
            }
            .time{
                font-size: 28px;
                text-align: left;
font-weight: 400;
color: #999999;
line-height: 40px;
            }
            .address{
              font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
            }
        }
        .right{
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
.ic{
  width:74px;
  height: 74px;
}
        }

        .sleft{
            display: flex;
            flex-direction: column;
            text-align: left;
            .title{
                font-size: 28px;
font-weight: 400;
color: #181818;
line-height: 40px;
            }
            .address{
                display: flex;
                align-items: center;
                font-size: 28px;
                .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
            }
        }
          .sright{
            display: flex;
            flex-direction: column;
            text-align: right;
        font-size: 28px;
font-weight: 400;
color: #181818;
.snum{
    font-weight: 500;
}
.time{
    font-size: 28px;
}
        }
    }
}
 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
        width:50%;
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
}
</style>