<template>
  <div class="main" :class="show ? 'h5' : ''">
        <!-- <mtop /> -->
      <!-- <div class="stop">
        <div @click="Back('/Cast')">
            <img src="../assets/img/back.png" alt="" srcset="" class="ico">
        </div>
        <div class="title">{{$t('m.yqzx')}}</div>
    </div>      -->
    <div class="yqcode">
      <div class="items">
        <div class="name">{{$t('m.gfyqm')}}</div>
        <div class="lines">{{address | yincang}}    
          <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="address"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
           <img src="../assets/img/fz.png" alt="" class="ic" srcset="">
          </button></div>
      </div>
        <div class="items">
        <div class="name">{{$t('m.wdyqm')}}</div>
        <div class="lines">{{this.$store.getters.userWallet|yincang }}    
          <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="this.$store.getters.userWallet"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
           <img src="../assets/img/fz.png" alt="" class="ic" srcset="">
          </button></div>
      </div>
    </div>
    <div class="content">   
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh"  
        :loosing-text="$t('m.loading')"
        :loading-text="$t('m.loading')">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
            :finished-text="$t('m.nomore')"
          style="min-height: 100vh"          
        >
          <div class="top">
              <div class="left">{{$t('m.beiyaoqingrendizhi')}}</div>
               <div class="right">{{$t('m.zhuzaoshulian')}}</div>
            </div>
          <div   class="list" >
             <div class="item"  v-for="(item, i) in list" 
            :key="i">
             <div class="sleft">
              <div class="address">{{item.address |yincang}}</div>              
              <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="item.address"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
           <img src="../assets/img/fz.png" alt="" class="ic" srcset="">
          </button>
             </div>
               <div class="sright">x{{ item.num }}</div>
            </div>  
             <div v-if="list.length == 0" class="noData">
            <!-- <img src="../assets/logo.png" alt="" srcset=""> -->
          </div>
          </div>
          </van-list>
      </van-pull-refresh>
        
    </div>
 
    <Tranfer
      :show="tshow"
      :data="plandata"
      @tshow="
        (val) => {
          tshow = val;
        }"
      @updata="
        (val) => {
          tshow = false;
          if(val){this.getmyNum()}
        }
      "
    />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
import {getZynum,Img,getChildren } from "@/api/Public";
export default {
  data() {
    return {
      email: "",
        show: false,
      Sendvue:0,
      active: 0,
        list: [],
        page: 1,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
        nftImg: Img,
        title:"V1",
      cur: 0,
      acdata: '',
      buynum: 0,
         address:"0x94489dC052B9e80a6a0fdD2f24F95433F4d2d73A"
    };
    },
    components: {
    mtop,
        mbottom,
    Tranfer
  },
    created() {
      this.title=this.$route.query.is
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },
    methods: {
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
        getmyNum() { 
            walletOfOwner(this.$store.getters.userWallet).then(res => {
                this.mynum = res
                this.onLoad()
                // this.list[0].length=this.mynum
            })
        },
        getNum(address, i) { 
            getZynum(address).then(res => {
                this.$set(this.list[i],'address',address)
                this.$set(this.list[i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 

        },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
                setTimeout(() => {
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
        },
        onLoad() { 
               this.loading = false;
            this.finished = true;
             this.list=[]
            getChildren(this.$store.getters.userWallet, this.type).then(res => {  
              if (res.length > 0) {               
                      res.map((el, i) => {
                         this.list.push({address:'',num:''})
                          this.getNum(el, i)
                      })
                    }
                })
         this.refreshing=false
        },
         onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
        togetChaildren() { 
          
        },
        tochanger(e) {
            this.active = e           
         },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
        },
        Back(e) { 
           this.$router.push({ path: e }) 
        },
    toPage(e) {
      if (e == this.$route.path||this.active<=0) {
        return;
      }
      this.$router.push({ path: e ,query:{id:this.active}});
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   
    padding-top:25px;
   .sheader{
    width: 100%;
    background: #F7F7F7;  
   }
   .bg{
    background: #F7F7F7;
   }
  
.yqcode{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width: 686px;
height: 142px;
background: #FFFFFF;
border-radius: 8px;
margin:0px auto 20px;
border: 2px solid #F0F0F0;
padding: 20px;
box-sizing:border-box;
  .items{
    display:flex;
    align-items:center;
    flex-direction:column;
    .name{
      font-size: 24px;
    }
    .lines{
      display:flex;
      margin-top:10px;
      font-size: 24px;
    }
  }
  .ic{
    width:22px;
    height:22px;
  }
}
    .stop{
        position: relative;
        width: 100%;
        height: 100px;
        .ico{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-50%);
        }
        .title{
            text-align: center;
            font-size: 32px;
font-weight: 400;
color: #333333;
line-height: 100px;
        }
    }
.content{
    background: #ffffff;
    .top{
         display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #F0F0F0;
            width:100%;
            box-sizing:border-box;
            font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
letter-spacing: 2px;

    }
    .list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      .item{
           display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #F0F0F0;
            width:100%;
  .sleft{
            display: flex;
            flex-direction: row;
            text-align: left;

            .title{
                font-size: 28px;
font-weight: 400;
color: #181818;
line-height: 40px;
            }
            .address{
                display: flex;
                align-items: center;
                font-size: 28px;
              
            }
              .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
        }
          .sright{
            display: flex;
            flex-direction: column;
            text-align: right;
        font-size: 28px;
font-weight: 400;
color: #181818;
.snum{
    font-weight: 500;
}
.time{
    font-size: 28px;
}
        }

        .nft{
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
        .top{
            position: absolute;
            right: 20px;
            top: 20px;
            .xz{
                width: 32px ;
                height: 32px;
            }
        }
        .bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            position: absolute;
            bottom: 10px;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
          .title{
            min-width: 92px;
            height: 42px;
            background: #0000007a;
            border-radius: 28px;
            // opacity: 0.5;
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
            letter-spacing: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
          }
          .right{
            font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 34px;
text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
          }
        }
      }
      .kongbai{
           width: 330px;
        height: 326px;
        background: transparent;
        border-radius: 16px;
        margin: 13px;
      }
    }
}
 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
    
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
    .rule {
    width: 100%;
    padding: 50px 32px 60px;
    box-sizing: border-box;
    .card {
      width: auto;
      margin: 0 auto;
      text-align: left;
      font-size: 26px;
      .p {
        width: auto;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 42px;
      }
    }
  }
  .bottomfilex{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.25);
backdrop-filter: blur(10px);
    padding: 32px 0;
    width: 100%;
    .btn{
         width: 686px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
  }
    .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
        width:50%;
        height:100%;

      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
}
</style>