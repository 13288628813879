<template>
  <div class="main" :class="show ? 'h5' : ''">
    <mtop />
    <div class="sheader">
      <div class="haerbox">
        <div class="nftimg">
          <img src="../assets/img/ulogo.png" alt="" srcset="" class="nft">
        </div>
        <div class="name">GRWA</div>
        <div class="dec">{{ $t('m.nodeText1') }}</div>
        <div class="dec">{{ $t('m.nodeText2') }}</div>


      </div>
    </div>


    <div class="container">
      <!-- Card 1 -->
      <div class="card">
        <div class="card-box">


          <img src="../assets/img/B_node.jpg" alt="大区节点" class="card-image" />
          <div class="card-info-text">
            <h3 class="card-title">大区节点</h3>
            <div class="card-text">
              <span>{{ reBalance }}</span>
              <span>/</span>
              <span>{{ reTotal }}</span>
            </div>

          </div>

        </div>
        <div>
          <p class="card-address">
            合约地址：{{ ReAddress | yingcang }}
          </p>
        </div>
        <div class="card-buttons">
          <van-button class="btn btn-buy" @click="buyRe" :loading="reBtn">购买</van-button>
          <van-button class="btn btn-transfer" @click="handleTransfer(1)">转发</van-button>
        </div>
      </div>

      <!-- Card 2 -->
      <div class="card">
        <div class="card-box">


          <img src="../assets/img/B_node.jpg" alt="大区节点" class="card-image" />
          <div class="card-info-text">
            <h3 class="card-title">区节点</h3>
            <div class="card-text">
              <span>{{ disBalance }}</span>
              <span>/</span>
              <span>{{ disTotal }}</span>
            </div>

          </div>

        </div>
        <div>
          <p class="card-address">
            合约地址：{{ DisAddress | yingcang }}
          </p>
        </div>
        <div class="card-buttons">
          <van-button class="btn btn-buy" @click="buyDis" :loading="disBtn">购买</van-button>
          <van-button class="btn btn-transfer" @click="handleTransfer(2)">转发</van-button>
        </div>
      </div>
    </div>
    <van-popup v-model="popShow">

      <div class="trans-pop">
        <div class="trans-title">转发</div>
        <div class="trans-main">
          <van-cell-group>
            <van-field v-model="transAddress" label="" placeholder="请输入地址" class="trans-input" />
          </van-cell-group>
          <div class="main-btn">
            <van-button class="confirm" @click="confirm" :loading="transStatu">确认</van-button>
            <van-button class="cancel" @click="popShow = false">取消</van-button>
          </div>
        </div>

      </div>


    </van-popup>



    <mbottom />
  </div>
</template>

<script>
import { ReNode, RegionNode_ADDRESS, DisNode_ADDRESS, DisNodeMint, reNodeExchangePrice, nodeApprove, reNodeBalance, reNodeTotal, disNodeTotal, disNodeBalance, disNodeApprove, DisNodeExchangePrice, reNodeSafeTransferFrom, disNodeSafeTransferFrom, checkBlockSyncStatus } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
export default {
  data() {
    return {
      show: false,
      popShow: false,
      ReAddress: RegionNode_ADDRESS,
      DisAddress: DisNode_ADDRESS,
      reBtn: false,
      disBtn: false,
      rePrice: 0,
      disPrice: 0,
      reBalance: 0,
      disBalance: 0,
      reTotal: 0,
      disTotal: 0,
      transAddress: '',
      type: 0,
      transStatu: false
    };
  },
  components: {
    mtop,
    mbottom,
    Tranfer
  },
  created() { },

  mounted() {

    console.log(RegionNode_ADDRESS)
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    this.getBuyBalance()
    this.getPrice()
    this.getTotal()
  },
  filters: {
    // 用户地址
    yingcang(e) {
      if (e) {
        return e.replace(
          /(\w{8})\w+(\w{8})/,
          "$1******$2"
        );
      } else {
        return "";
      }
    },
  },
  watch: {

  },
  methods: {

    confirm() {
      this.transStatu = true
      if (this.type == 1) {

        reNodeSafeTransferFrom(this.$store.getters.userWallet, this.transAddress).then(res => {
          console.log(res)
          if (res.success === true) {
            this.popShow = false
            this.$notify({ message: '转发成功', type: "success" });
          } else {
            this.$notify("转发失败");
          }
          this.transStatu = false
        })
      } else {

        disNodeSafeTransferFrom(this.$store.getters.userWallet, this.transAddress).then(res => {
          console.log(res)
          if (res.success) {
            this.popShow = false
            this.$notify({ message: '转发成功', type: "success" });
          } else {
            this.$notify("转发失败");
          }
          this.transStatu = false
        })

      }
    },
    handleTransfer(type) {
      this.popShow = true
      this.type = type
    },
    getTotal() {
      reNodeTotal().then(res => {
        console.log(res)
        if (res.success) {
          this.reTotal = res.res
        }
      })
      disNodeTotal().then(res => {

        if (res.success) {
          this.disTotal = res.res
        }
      })

    },
    getBuyBalance() {

      reNodeBalance(this.$store.getters.userWallet).then(res => {

        if (res.success) {
          this.reBalance = res.res
        }
      })
      disNodeBalance(this.$store.getters.userWallet).then(res => {

        if (res.success) {
          this.disBalance = res.res
        }
      })
    },
    handleApprove() {
      nodeApprove(this.$store.getters.userWallet, this.rePrice).then(res => {
        if (res.success) {
          this.mintRe()
        } else {
          this.reBtn = false
        }
      })


    },
    getPrice() {
      reNodeExchangePrice().then(res => {
        if (res.success) {
          this.rePrice = res.res
        }
      })
      DisNodeExchangePrice().then(res => {
        if (res.success) {
          this.disPrice = res.res
        }
      })
    },
    disApprove() {
      disNodeApprove(this.$store.getters.userWallet, this.disPrice).then(res => {
        if (res.success) {
          this.mintDis()
        } else {
          this.reBtn = false
        }
      })
    },
    buyDis() {
      this.disBtn = true
      this.disApprove()
    },
    buyRe() {
      this.reBtn = true
      this.handleApprove()
    },
    mintRe() {
      ReNode(this.$store.getters.userWallet).then(res => {
        if (res.success) {
          this.reBtn = false
          checkBlockSyncStatus().then(res => {

            if (res.success===true) {
              this.getBuyBalance()
              this.$notify({ message: '购买成功', type: "success" });
            }
          })

        } else {
          this.reBtn = false
          this.$notify("购买失败");
        }
      }).catch(err => {
        this.reBtn = false
        this.$notify("购买失败");
      })
    },

    mintDis() {
      DisNodeMint(this.$store.getters.userWallet).then(res => {
        if (res.success) {
          this.disBtn = false
          checkBlockSyncStatus().then(res => {

            if (res.success===true) {
              this.getBuyBalance()
              this.$notify({ message: '购买成功', type: "success" });
            }
          })
        } else {
          this.disBtn = false
          this.$notify("购买失败");
        }
      }).catch(err => {
        this.disBtn = false
        this.$notify("购买失败");
      })
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";

.main {
  background: #ffffff;

  .sheader {
    width: 100%;
    background: url("../assets/img/node_bg.jpg") no-repeat top center;
    background-size: cover;

    .haerbox {
      width: 100%;
      height: 100%;
      min-height: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 109px;
      padding-bottom: 32px;

      .nftimg {
        position: relative;

        .nft {
          width: 100%;
          height: 130px;
        }

        .num {
          position: absolute;
          right: -60px;
          bottom: 0;
          font-size: 28px;
          font-weight: 500;
          color: #ffffff;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }

      .name {
        font-size: 32px;
        font-weight: 500;
        color: #ffffff;
        line-height: 44px;
        margin: 14px 0;
      }

      .dec {
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        margin: 0px 24px 10px;
        text-align: left;
      }
    }
  }



}
</style>
<style scoped>
/* 容器样式 */
.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;

}

/* 卡片样式 */
.card {
  width: 600px;
  padding: 10px;

  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  font-family: Arial, sans-serif;
  margin-bottom: 30px;
}

.card-box {
  display: flex;
  justify-content: space-around;
}


.card-info-text {
  display: flex;
  flex-direction: column;
}


.card-text {
  border: 1px solid #000;
  font-size: 35px;
  width: 200px;
  padding: 15px 0;
  margin-top: 20px;
}

/* 图片样式 */
.card-image {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;

}

/* 标题样式 */
.card-title {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* 信息样式 */
.card-info {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #000;
}

/* 地址样式 */
.card-address {
  font-size: 28px;
  color: #000;
  margin-bottom: 20px;
  word-wrap: break-word;
}

/* 按钮容器 */
.card-buttons {
  width: 520px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

/* 按钮样式 */
.btn {
  width: 220px;
  height: 70px;

  padding: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-buy {
  background: rgba(0, 146, 112, 1);
  box-shadow: 0px 1.06px 2.11px rgba(0, 0, 0, 0.25);

  color: #fff;
}



.btn-transfer {
  background: rgba(255, 255, 255, 1);

  border: 1.06px solid rgba(255, 255, 255, 1);

  box-shadow: 0px 1.06px 2.11px rgba(0, 0, 0, 0.25);
  color: rgba(26, 143, 114, 1);
}

.btn-transfer:hover {
  background-color: #f0f0f0;
}

.trans-pop {
  width: 600px;
  padding: 20px 0 100px 0;
  display: flex;
  flex-direction: column;
}

.trans-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  padding-top: 20px;
}

.trans-main {
  width: 500px;
  margin: 0 auto;
}

.trans-input {

  font-size: 30px;
}

.main-btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.main-btn .confirm {
  width: 190px;
  height: 70px;
  background: rgba(0, 146, 112, 1);
  color: #fff;
  font-size: 22px;
}

.main-btn .cancel {
  width: 190px;
  height: 70px;
  background: rgba(255, 255, 255, 1);
  color: rgba(26, 143, 114, 1);
  font-size: 22px;
  margin-left: 20px;
}
</style>