/**
 * --------活动一--------------
 */

import Web3 from 'web3'
 import NFTP from './fil.json'
import NFT from './abi-ap.json'       // ERC721 abi
import ZYNFT from './zyabi.json'
import ntAbi from './nt.json'
import  DisNodeABI from './DisNode.json'
import  ReNodeABI from './ReNode.json'
import store from '../store/index'
const keccak256 = require('keccak256')
import { Toast,Notify } from 'vant';
import WalletConnectProvider from "@walletconnect/web3-provider";
export const ADDRESS_NFT = "0xA75a4352ba338163c95dC537EcEA2C3D1A103005"   //  Mint合约地址 ERC721合约
export const ADDRESS_ZYNFT = "0xFe2AEb608A65e22AFf6deF70B225e82221FbaA56"
 const ADDRESS_NFT_APPROVE = "0xFB2dE5BDb6eB378d7fA650F0bA3b6Ea50befCe3a"; // 授权合约地址 ERC20合约
const NT_ADDRESS="0xB6cE289622cb2D9DC128199Af57c2Cfff7B75a5d"
export const  RegionNode_ADDRESS="0xA3Eb0AEe604839b13Ed320A13905914EeA533458"//大区节点合约
export const  DisNode_ADDRESS="0x8916E9F028432c39794523C1856Cc22131a29a58"//区节点合约

// 测试版
// export const ADDRESS_NFT = "0x31Db7fD97F35994E7d622EEf9F76bBE3136a750d"   //  Mint合约地址 ERC721合约
// export const ADDRESS_ZYNFT = "0x0Cc620Ef526681a154C9282621660b60039Ade73"
// const ADDRESS_NFT_APPROVE = "0x3afBC51AC9727DAA75080129d55E95BfCc2F8313"; // 授权合约地址 ERC20合约


// const ADDRESS_NFT_APPROVE = "0xaD8d1833283c75e161DB060550fD13FA9C91663E"; // 授权合约地址 ERC20合约

export const SKye ="BMIW9MI7Y5THPTKHBR72A8B4KT2SFCJ12F"
export const ImgV1 = require('../assets/img/V1.png')
export const ImgV2 = require('../assets/img/V2.png')
export const ImgV3 = require('../assets/img/V3.png')
export const ImgV4 = require('../assets/img/V4.png')
const mlist = []

let ethereum = window.ethereum
let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {   
        handleWatch()
        ethereum = window.ethereum
        // connectWeb3(56)
        // connectWeb3(97)
        connectWeb3(11155111)
        clearInterval(i)
    }
}, 1000);


export async function set(val) {
    let web3s = new Web3(ethereum);
    if (val == ethereum.chainId) {
        return true
    } else {
        return false
    }

}
export async function setBk() {
    const isBitKeepInstalled = window.isBitKeep && window.bitkeep.ethereum
    if (isBitKeepInstalled) {
        ethereum = window.bitkeep.ethereum
        console.log('BitKeep Extension is installed!');
        return true

    } else {
        Toast('BitKeep Extension No installed!')
        return false
    }
}
export async function setTp() {
    if (typeof window.ethereum.isTokenPocket !== 'undefined') {
        console.log('TokenPocket Extension is installed!');
        return true
    } else {
        Toast('TokenPocket Extension No installed!')
        console.log('TokenPocket Extension No installed!');
        return false
    }
    //     let web3s = new Web3(ethereum);
    //     window.utilWeb3 = web3s.utils;
    //     window.ContractEth = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
    //     from: ethereum.selectedAddress
    // });

}
export async function sets() {
    const provider = new WalletConnectProvider({
        rpc: {
            2213: "https://chain.xingrunkeji.cn",
            56: "https://bsc-dataseed.binance.org",
            1: "https://mainnet.mycustomnode.com",
            3: "https://ropsten.mycustomnode.com",
            100: "https://dai.poa.network",
        },

    });
    await provider.enable();
    ethereum = new Web3(provider);
}


export async function connectWeb3(val) {
    // 判断链对不，链不对就请求切换网络，或者添加网络，
    if (window.ethereum) {
        let web3s = new Web3(ethereum);
        console.log(ethereum.chainId);
        if (val == ethereum.chainId) {
            return true
        }
        try {
            // let a = Web3.utils.hexToNumberString('0x534e5f4d41494e')
            //// console.log(val);
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: Web3.utils.numberToHex(val), // 目标链ID
                }]
            })
            return true
        } catch (e) {
            // console.log('//---------', e.code);
            if (e.code == 4902) {
                try {
                    // await window.ethereum.request({
                        // method: 'wallet_addEthereumChain',
                        // params: [{
                        //     // chainId: Web3.utils.numberToHex(56), 
                        //     chainId: Web3.utils.numberToHex(97), // 目标链ID
                        //     chainName: 'Base Goerli',
                        //     nativeCurrency: {
                        //         name: 'Currency Symbol ETH',
                        //         symbol: 'Currency Symbol ETH',
                        //         decimals: 18
                        //     },
                        //     // rpcUrls: ['https://goerli.base.org'], // 节点
                        //     rpcUrls:['https://data-seed-prebsc-1-s1.bnbchain.org:8545'],
                        //     blockExplorerUrls: ['https://goerli.basescan.org']
                        // }]
                        // params: [{
                        //     chainId: '0x534e5f4d41494e', // 目标链ID
                        //     chainName: 'Starnet​',
                        //     nativeCurrency: {
                        //         name: 'Starnet',
                        //         symbol: 'Currency Symbol ETH',
                        //         decimals: 18
                        //     },
                        //     rpcUrls: ['https://alpha-mainnet.starknet.io'], // 节点
                        //     blockExplorerUrls: ['https://goerli.basescan.org']
                        // }]
                    // })
                    return true
                } catch (ee) {
                    //console.log('//---------', ee);

                }
            } else if (e.code === 4001) return false
        }
    }
}
export async function connectBk() {
    let a = await setBk()
    if (a) {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        return accounts
    }
}

export async function connectTp() {
    let a = await setTp()
    if (a) {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        return accounts
    }
}

export async function connect() {
    await sets()
    const accounts = await ethereum.eth.getAccounts();
    return accounts
}

export async function disconnect(type) {
    if (type == 2) {
        const provider = new WalletConnectProvider({
            rpc: {
                2213: "https://chain.xingrunkeji.cn",
                56: "https://bsc-dataseed1.ninicoin.io",
                1: "https://mainnet.mycustomnode.com",
                3: "https://ropsten.mycustomnode.com",
                100: "https://dai.poa.network",
            },
        });

        let res = await provider.disconnect();
        if (res) {
            console.log('----------', res);
            sessionStorage.removeItem('userWallet')
        }
        return res
    } else {
        sessionStorage.removeItem('userWallet')
        return true
    }
};




//链接钱包
export async function connectTron() {
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    return eth_requestAccounts
}

// 获得U
export function getmyUsdt(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        console.log(address)
        console.log(NFTP);
        console.log(ADDRESS_NFT_APPROVE)
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);
        let result = await unioContract.methods.balanceOf(address).call().catch(err => {
            // Toast(err.message)
        });
        console.log("result",result)
        return web3s.utils.fromWei(result, 'ether');

    };
    return run();
}
export async function ConstructMerkleTree(address, num, type) {
    if (type == 2) { await sets() }
    let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
    let balance =  await web3s.eth.getBalance(address).then(res => { return res});
    console.log(balance);
    if (balance - gas> 0) {    

        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
      
        let result = await unioContract.methods
            .mint(num)
            .send({
                from: address,
                gasPrice: gas
            }).catch(err => {
                console.log(err);
                // Toast(err.message)
                return false
            });
    
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    } else {
        gas = web3s.utils.fromWei(gas, 'gwei');
        Toast('gas undercharge ' + gas + 'Gwei')
    }
  
}

export async function getParent(address) {
    let web3s = new Web3(ethereum);

    let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
      
    let result = await unioContract.methods
        .parent(address).call().catch(err => {
            return false
        });
        return result

}


// mintWithParent
export async function bindParent(buynum,parent,userAddress) {
    


        try {
            let web3s = new Web3(ethereum);

            let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        
            let result = await unioContract.methods
                .mintWithParent(buynum,parent)
                .send({
                    from: userAddress
                }).catch(err => {
                   failResult(err)
                });

                successResult(result)
        } catch (error) {
            failResult(error)
        }
    
}

// 获得铸造记录
export function getstakerTokens(address, page) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT);
        let result = await unioContract.methods.stakerTokens(address, page,1000000000000).call().catch(err => {
            // Toast(err.message)
            return false
        });
        return result

    };
    return run();
}


// 获得释放天数
export function getstakerDay(address, sid) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT);
        let result = await unioContract.methods.releaseDayNum(address, sid ).call().catch(err => {
            // Toast(err.message)
        });
        return result

    };
    return run();
}
// 获得授权数
export function getApprove(address,type) {
 
    let run = async () => {
        if (type == 3) {
            await setTp()
        }
        if (type == 2) { await sets() }
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);
        let result = await unioContract.methods.allowance(address,ADDRESS_NFT).call().catch(err => {
            // Toast(err.message)
        });
        return web3s.utils.fromWei(result, 'ether');

    };
    return run();
}

// 设置授权
export function setApprove(address, amount, type) {
    let run = async () => {
        if (type == 3) {
            await setTp()
        }
        if (type == 2) { await sets() }
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);
        let balance = await web3s.eth.getBalance(address).then(res => { return res });
        let num = web3s.utils.toWei(1 * amount + '', 'ether');
        let gas = await web3s.eth.getGasPrice()
        if (balance - gas >= 0) {
            let result = await unioContract.methods.approve(ADDRESS_NFT, num).send({
                from: address,
                gasPrice: gas
            }).catch(err => {
                console.log(JSON.stringify(err));
                // Toast(err.message)
                return false
            });
            return result;
        } else {
            gas = web3s.utils.fromWei(gas, 'gwei');
            Toast('gas undercharge ' + gas+'Gwei')
        }

    };
    return run();
}

export async function handleWatch() {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
    }
    window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts[0]) {
            sessionStorage.setItem('userWallet', accounts[0])
            store.commit("userWallet", accounts[0]);
        } else {
            sessionStorage.removeItem('userWallet')
        }
    })
}

//合成
export async function toCompose(ids, amount, targetId, address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let gas = await web3s.eth.getGasPrice()
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.compose(ids, amount, targetId).send({
            from: address,
            gasPrice: gas
        }).catch(err => {
            // Toast(err.message)
            console.log(err);
            return false
        });
        console.log('result', result);
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    };
    return run();
}
// 铸造
export async function toStake(address, num, parent) {
    let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
    let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT);
  
    if (parent) { 
        console.log(address, num, parent);
        let result = await unioContract.methods
            .stakeWithParent(num,parent)
            .send({
                from: address,
                gasPrice: gas
            }).catch(err => {
                // Toast(err.message)、
                console.log(err);
                return false
            });
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    } else {
        let result = await unioContract.methods
            .stake(num)
            .send({
                from: address,
                gasPrice: gas
            }).catch(err => {
                // Toast(err.message)
                console.log(err);
                return false
            });
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    }

}



//投资
export async function invest(address, amount) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT);
        let gas = await web3s.eth.getGasPrice()
        let result = await unioContract.methods.claimRewards(amount)
            .send({ from: address, gasPrice: gas })
            .catch(err => {
            // Toast(err.message)
            console.log(err);
            return false
        });
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    };
    return run();
}
export async function SendNft(id,address, amount, userWallet) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let gas = await web3s.eth.getGasPrice()
        let data = 0
        console.log(userWallet, address, id, amount, data);
        let result = await unioContract.methods.safeTransferFrom(userWallet, address, id, amount, data)
            .send({ from: userWallet, gasPrice: gas }).catch(err => {
                // Toast(err.message)
                console.log(err);
                return false
        });
        if (result) {
            return result.transactionHash
        } else {
            return false
        }
    };
    return run();
}

// 查询额度
export async function balance() {
    let run = async () => {
        console.log(1);
        let web3s = new Web3(ethereum);
        let result = await web3s.eth.getBalance(address).then(res => { return res / 10 ** 18 });
        return result;
    };
    return run();
}

// 查询腹肌

export async function getCost(address,type) {
    let run = async () => {
        if (type == 2) { await sets() }
        console.log(address);
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT,ADDRESS_ZYNFT);
        let result = await unioContract.methods.parent(address).call()
        console.log(result,'--------');
        if (result != '0x0000000000000000000000000000000000000000') { return true } else {
            return false
        }
    };
    return run();
}


//mint前查询父级
// export async function getCost(address,type) {
//     let run = async () => {
//         if (type == 2) { await sets() }
//         console.log(address);
//         let web3s = new Web3(ethereum);
//         let unioContract = await new web3s.eth.Contract();
//         let result = await unioContract.methods.parent(address).call()
//         console.log(result,'--------');
//         if (result != '0x0000000000000000000000000000000000000000') { return true } else {
//             return false
//         }
//     };
//     return run();
// }

export async function getChildren(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT);
        let result = await unioContract.methods.children(address).call()
        console.log(result, '--------');
        if (result != '0x0000000000000000000000000000000000000000') { return result } else {
            return false
        }
    };
    return run();
}




// 查询Mode
export function getMode() {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.getMode().call()
        return result;
    };
    return run();
}


// 查询当前铸造数量 总数量
export function gettotal(type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.totalSupply(type).call()
      
        return result;
    };
    return run();
}

// 查询当前价格
export function getPrice(type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.exchangePrice().call()
        if (result) {
            return web3s.utils.fromWei(result, 'ether');
        } else {
            return  0
        }
    };
    return run();
}

// 查询拥有的NFT
export function getToken(address, type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
            from: address
        });
        let result = await unioContract.methods.tokenOfOwneerByIndex(address,0).call()
        // console.log(result, 'num--');
        return result;
    };
    return run();
}

// 查询拥有的NFT
export function walletOfOwner(address,act, type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
            from: address
        });
        let result = await unioContract.methods.balanceOf(address, act).call()
        // console.log(result, 'num--');
        return result;
    };
    return run();
}

// 查询铸造的NFT
export function getZynum(address, type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ZYNFT, ADDRESS_ZYNFT, {
            from: address
        });
        let result = await unioContract.methods.balanceOfAmount(address).call()
        // console.log(result, 'num--');
        return result;
    };
    return run();
}

// 获取图片地址
export function tokenURI(token, type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.tokenURI(token).call()
        return result;
    };
    return run();
}


// 获取合约名称
export function getContractName(type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.name().call()
        return result;
    };
    return run();
}



// 转账
export function Transfer(address,to,type) {
    let run = async () => {
        // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        console.log(address, to);
        let token = await unioContract.methods.tokenOfOwnerByIndex(address, 0).call()
        if (token > 0) {
            let result = await unioContract.methods.transferFrom(address, to, token).send({ from: address }).catch((err) => {
                console.log(err);
                return false
            })
            return result;
        } else {
            return false
        }
      
    };
    return run();
}









export async function ConstructMerkleTree4(address, type) {
    address = ""
    // if (whitelist.length != mlist.length) { await set() }
    if (type == 2) { await sets() }
    const leafNodes6 = mlist.map(address => keccak256(address)); //INIT2:SWITCH ROOT
    // console.log(leafNodes6);
    const merkleTreeG = new MerkleTree(leafNodes6, keccak256, { sortPairs: true });
    // console.log(merkleTreeG);
    const G_P = merkleTreeG.getHexProof(keccak256(address));
    console.log("GOOD ROOT", "0x".concat(merkleTreeG.getRoot().toString('hex')));
    // console.log('gp-----',G_P);
    if (G_P.length > 0) { return true } else { return false }
}






// 判断类型  true 其他（非盲盒）  false 盲盒 

export function getRevealed() {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.revealed().call()

        return result;
    };
    return run();
}



export function toSing(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let result = await web3s.eth.sign('123',address).then(res=>{return res})
        return result;
    };
    return run();
}





export  async function sendTrans(info){
   

      try {
        console.log("info______",info)
        let rpc_link='https://bsc-mainnet.rpcfast.com?api_key=xbhWBI1Wkguk8SNMu1bvvLurPGLXmgwYeC4S6g2H7WdwFigZSmPWVZRxrskEQwIf'
        const w3_rpc =  new Web3(rpc_link)	

        const  res=await w3_rpc.eth.sendSignedTransaction(info)
        console.log(res)
        return  successResult(res)
      } catch (error) {
        console.log("错误信息——————————",error)
        let info
        if(error=='Error: Returned error: insufficient funds for gas * price + value'){
          info='账户中的资金不足以支付交易的燃料费用'
        }else{
          info=error
        }
         
            return failResult(info)
          
       
      }
  
  
  }


  export  async function getNoce(address){

    try {

      let rpc_link='https://greatest-sparkling-yard.bsc.quiknode.pro/8d4444ca8342fc7fe70c59bc37e5d5cc107ebd80/'
      let w3 = new Web3(ethereum);
   let noce =await  w3.eth
            .getTransactionCount(address)
            .then((res) => {
              return res;
            });
      console.log("h5____noce",noce)
      return  successResult(noce)
    } catch (error) {
     
       
          return failResult(error)
        
     
    }


}

export const getNtbalanceOf=async(address)=>{

	try {
        let web3s =await new Web3(ethereum);
        let ntContracts = await new web3s.eth.Contract(ntAbi, NT_ADDRESS);
        let result =await ntContracts.methods.balanceOf(address).call()
        // let money= web3s.utils.fromWei(result, 'ether');
        console.log(result)
		return successResult(result)
	} catch (error) {
		return failResult(error)
	}
}


  

// 统一错误返回
export const failResult =(res) => ({
    success: false,
    res
  })
  
  // 统一成功返回
  export const successResult = (res)=> ({ 
    success: true,
     res
 })




 export async function ReNode(address) {
    console.log(address)
    let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()

    let unioContract = await new web3s.eth.Contract(ReNodeABI , RegionNode_ADDRESS);
    let result = await unioContract.methods
    .mint(1)
    .send({
        from: address,
        gasPrice: gas
    }).catch(err => {
        console.log(err);
        // Toast(err.message)
        return false
    });

}


export async function nodeApprove(address,num) {


try {
    let web3s =await new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
    let nums = web3s.utils.toWei(1 * num + '', 'ether');
    let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);
    let result = await unioContract.methods.approve(RegionNode_ADDRESS, nums).send({from: address,gasPrice: gas})
    return successResult(result)
} catch (error) {
    console.log(error)
        return failResult(error)
}
}

export async function disNodeApprove(address,num) {


    try {
        let web3s =await new Web3(ethereum);
        let gas = await web3s.eth.getGasPrice()
        let nums = web3s.utils.toWei(1 * num + '', 'ether');
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);
        let result = await unioContract.methods.approve(DisNode_ADDRESS, nums).send({from: address,gasPrice: gas})
        return successResult(result)
    } catch (error) {
        console.log(error)
            return failResult(error)
    }
    }

export async function reNodeTotal() {


    try {
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ReNodeABI , RegionNode_ADDRESS);
        let result =await unioContract.methods.totalSupplyLimit(0).call()

          return  successResult(result)
        
    } catch (error) {

        return failResult(error)
    }

}


export async function disNodeTotal() {


    try {
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DisNodeABI , DisNode_ADDRESS);
        let result =await unioContract.methods.totalSupplyLimit(0).call()

          return  successResult(result)
        
    } catch (error) {

        return failResult(error)
    }

}

export async function disNodeBalance(address) {


    try {
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DisNodeABI , DisNode_ADDRESS);
        let result =await unioContract.methods.balanceOf(address,0).call()

          return  successResult(result)
        
    } catch (error) {

        return failResult(error)
    }

}

export async function reNodeBalance(address) {


    try {
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ReNodeABI , RegionNode_ADDRESS);
        let result =await unioContract.methods.balanceOf(address,0).call()

          return  successResult(result)
        
    } catch (error) {

        return failResult(error)
    }

}

export async function reNodeExchangePrice() {
    try {
        
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ReNodeABI , RegionNode_ADDRESS);
        let result =await unioContract.methods.exchangePrice().call()
        let money= web3s.utils.fromWei(result, 'ether');
        console.log(money)
		return successResult(money)
	} catch (error) {
		return failResult(error)
	}

}


export async function DisNodeExchangePrice() {
    try {
        
        let web3s =await new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DisNodeABI , DisNode_ADDRESS);
        let result =await unioContract.methods.exchangePrice().call()
        let money= web3s.utils.fromWei(result, 'ether');
        console.log(money)
		return successResult(money)
	} catch (error) {
		return failResult(error)
	}

}
export async function DisNodeMint(address) {
    try {
        let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
        let num=1
    let unioContract = await new web3s.eth.Contract(DisNodeABI , DisNode_ADDRESS);
    let result = await unioContract.methods.mint(num).send({
        from: address,
        gasPrice: gas
    }).catch(err => {
        console.log(err);
        // Toast(err.message)
    });
    return  successResult(result)
    } catch (error) {
        
        return failResult(error)
    }
    
}


export async function reNodeSafeTransferFrom(userWallet,address) {


    try {
        let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
    const data = "0x";    
    const id=0
    const amount=1
    let unioContract = await new web3s.eth.Contract(ReNodeABI , RegionNode_ADDRESS);
    let result = await unioContract.methods.safeTransferFrom(userWallet, address, id, amount, data).send({
        from: userWallet,
        gasPrice: gas

    }).catch(err => {
        console.log(err);
        return failResult(error)
    });
    console.log(result)
    return  successResult(result)
    } catch (error) {
        
        return failResult(error)
    }
    
}

export async function disNodeSafeTransferFrom(userWallet,address) {
    try {
        let web3s = new Web3(ethereum);
    let gas = await web3s.eth.getGasPrice()
    const data = "0x";    
    const id=0
    const amount=1
    let unioContract = await new web3s.eth.Contract(DisNodeABI , DisNode_ADDRESS);
    let result = await unioContract.methods.safeTransferFrom(userWallet, address, id, amount, data).send({
        from: userWallet,
        gasPrice: gas
    }).catch(err => {
        return failResult(error)
    });
    return  successResult(result)
    } catch (error) {
        
        return failResult(error)
    }
}


export async function checkBlockSyncStatus() {
    try {
      // 使用 Web3 初始化
      let web3s = new Web3(ethereum);
  
      // 调用 isSyncing 方法检查同步状态
      let syncStatus = await web3s.eth.isSyncing();
  

        return successResult(syncStatus);

    } catch (error) {
      return {
        success: false,
        error: error.message || error,
      };
    }
  }









