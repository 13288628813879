var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",class:_vm.show ? 'h5' : ''},[_c('div',{staticClass:"sheader"},[_vm._m(0),(_vm.acdata)?_c('div',{staticClass:"haerbox"},[_c('div',{staticClass:"nftimg"},[_c('img',{staticClass:"nft",attrs:{"src":_vm.acdata.img,"alt":"","srcset":""}})]),_c('div',{staticClass:"f14"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.acdata.name))]),_c('div',{staticClass:"dec"},[_vm._v(_vm._s(_vm.mynum)+_vm._s(_vm.$t('m.mei')))])]),_c('van-stepper',{staticClass:"mint",attrs:{"max":_vm.mynum,"input-width":"50px","min":0,"button-size":"32px"},model:{value:(_vm.buynum),callback:function ($$v) {_vm.buynum=$$v},expression:"buynum"}})],1):_vm._e()]),_c('div',{staticClass:"navtab"},[_c('div',{staticClass:"stitle"},[_vm._v(" "+_vm._s(_vm.$t('m.jieshoudizhi'))+" ")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t('m.qingshurushoukuandizhi')},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}})])]),_c('div',{staticClass:"bottomfilex"},[_c('van-button',{staticClass:"btn",attrs:{"loading":_vm.loading||_vm.uniLoading,"disabled":_vm.cansend,"type":"primary","loading-text":"Loading..."},on:{"click":_vm.toSend}},[_vm._v(" "+_vm._s(_vm.$t('m.queren')))])],1),_c('Tranfer',{attrs:{"show":_vm.tshow,"data":_vm.plandata},on:{"tshow":(val) => {
         _vm.tshow = val;
       },"updata":(val) => {
         _vm.tshow = false;
         if(val){this.getmyNum()}
       }}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"haerbox"},[_c('div',{staticClass:"nftimg"},[_c('img',{staticClass:"her",attrs:{"src":require("../assets/logo.png"),"alt":"","srcset":""}})]),_c('div',[_c('div',{staticClass:"name"},[_vm._v("GRWA")]),_c('div',{staticClass:"dec"},[_vm._v("BNB Chain·BEP-1155")])])])
}]

export { render, staticRenderFns }