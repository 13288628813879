import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../view/Home.vue'
import Connect from "../view/Connect.vue";
import User from '../view/User.vue'
import Craft from '../view/Craft.vue'
import Cast from '../view/Cast.vue'
import CraftInfo from '../view/CraftInfo.vue'
import Record from '../view/Record.vue'
import Nftlist from '../view/Nftlist.vue'
import Send from '../view/Send.vue'
import Invite from '../view/Invite.vue'
import transNT from '../view/transNT.vue'
import Node from '../view/Node.vue'
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/Connect",
        name: "Connect",
        component: Connect,
    },
    {
        path: "/User",
        name: "User",
        component: User,
    },
    {
        path: "/Craft",
        name: "Craft",
        component: Craft,
    },
    {
        path: "/Cast",
        name: "Cast",
        component: Cast,
    },
    {
        path: "/CraftInfo",
        name: "CraftInfo",
        component: CraftInfo,
    },
    {
        path: "/Record",
        name: "Record",
        component: Record,        
    },
    {
        path: "/Nftlist",
        name: "Nftlist",
        component: Nftlist
    },
    {
        path: "/Send",
        name: "Send",
        component: Send
    },
    {
        path: "/Invite",
        name: "Invite",
        component: Invite
    },
    {
        path: "/transNT",
        name: "transNT",
        component: transNT
    },
    {
        path: "/Node",
        name: "Node",
        component: Node
    },
    
    
    
];
const router = new VueRouter({
    // mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) { //设置回到顶部
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})

export default router
