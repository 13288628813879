<template>
  <div class="main" :class="show ? 'h5' : ''">
        <!-- <mtop /> -->
      <!-- <div class="stop">
        <div @click="toPage('/Cast')">
            <img src="../assets/img/back.png" alt="" srcset="" class="ico">
        </div>
        <div class="title">{{$t('m.lingqujilu')}}</div>
    </div>   -->
    <div class="sheader" >
        <div class="haerbox">
        <div class="name">{{total}} GRWA</div>
        <div class="dec">{{$t('m.leijilingqu')}}</div>
        </div>        
    </div>
 
    <div class="line"></div>
    <div class="content">
    <div class="stopm">
      <div class="stitle">{{$t('m.lingqushuliang')}}</div>
      <div class="stitle" >{{$t('m.lingqushijian')}}</div>
      </div>   
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh"  
        :loosing-text="$t('m.loading')"
        :loading-text="$t('m.loading')">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
            :finished-text="$t('m.nomore')"
          style="min-height: 80vh"
          
        >
          <div
            v-for="(item, i) in list"
            :key="i"
            class=""
          >
             <div class="item" >
                <div class="sleft">
                    <div class="title">+{{item.num}} GRWA</div>
                    <!-- <div class="address">{{item.address| yincang}}                         
                          <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="item.address"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
           <img src="../assets/img/fz.png" alt="" class="ic" srcset="">
          </button>
                       </div> -->
                </div>
                <div class="sright">
                    <div class="snum">{{ item.time }}</div>
                </div>
            </div>
           
          </div>
          <div v-if="list.length == 0" class="noData">
            <!-- <div class="ntxt">{{$t('m.zwsj')}}</div> -->

          </div>
          <div class="kongbai"></div>
        </van-list>
      </van-pull-refresh>

    </div>
 

  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import {walletOfOwner,Img,getChildren } from "@/api/Public";
export default {
  data() {
    return {
      email: "",
      show: false,
      active: 0,
        list: [],
        page: 1,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
      nftImg: Img,
        total:0,
      cur: 0,
        base: [
            { id:1,
              name: "黄金NFT",
              dec: "一句话介绍黄金NFT",
              img:Img
            },
          {id:2,
              name: "钻石NFT",
              dec: "一句话介绍钻石NFT",
              img:Img
            },
          {id:3,
              name: "创世NFT",
              dec: "一句话介绍创世NFT",
              img:Img
          }
      ],
      acdata: '',
         buynum:0,
    };
    },
    components: {
    mtop,
        mbottom
  },
    created() {
      this.acdata=this.base[this.$route.query.id-1]
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },
    methods: {
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
        getmyNum() { 
            walletOfOwner(this.$store.getters.userWallet).then(res => {

                this.mynum = res
                this.onLoad()
                // this.list[0].length=this.mynum
            })
        },
        getNum(address, i) { 
            walletOfOwner(address).then(res => {

                this.$set(this.list[this.active][i],'address',address)
                this.$set(this.list[this.active][i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 
   let list = localStorage.getItem(this.$store.getters.userWallet)
   console.log(list)
         this.list=list?JSON.parse(list):[]
        },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
                setTimeout(() => {
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
        },
        onLoad() { 
               this.loading = false;
            this.finished = true;
            if (this.active == 2) {
              this.togetChaildren()
          }
          this.total=0
           let list = localStorage.getItem(this.$store.getters.userWallet)
          this.list = list ? JSON.parse(list) : []
          this.list.map(el => {
          this.total= this.total+el.num
         })
         this.refreshing=false
        },
         onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
        togetChaildren() { 
            this.list[2]=[]
            getChildren(this.$store.getters.userWallet, this.type).then(res => {  
                  if (res.length > 0) {                   
                      res.map((el, i) => {
                         this.list[2].push({address:'',num:''})
                          this.getNum(el, i)
                      })
                    }
                })
        },
        tochanger(e) {
            this.active = e
            if (e == 2) {
                 this.loading = true;
                 setTimeout(() => {
                    this.togetChaildren()          
                 }, 100);
                   
            }
         },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   .sheader{
    width: 100%;
    background: #ffffff;
    .haerbox{
          width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 48px 0;
      .nftimg{
        position: relative;
        margin-top: 28px;
        .nft{
            width: 338px;
            height: 338px;
            border-radius: 18px;
        }
        .num{
            position: absolute;
            right: -60px;
            bottom: 0;
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }
      }
      .name{
        font-size: 32px;
font-weight: 500;
color: #181818;
line-height: 44px;
margin: 24px 0 0px;
      }
      .dec{
        font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
      }
      .snum{
        width: 204px;
height: 60px;
border-radius: 8px;
border: 2px solid #009270;
font-size: 32px;
font-weight: 400;
color: #009270;
line-height: 60px;
margin: 10px auto 48px;
      }
        .btn{
      width: 336px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
    }
  
   }
   .bg{
    background: #F7F7F7;
   }
   .navtab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;    
        border-radius: 48px 48px 0 0;
    background: #ffffff;
     .stitle{
            text-align: left;
      font-size: 24px;
font-weight: 400;
color: #999999;
line-height: 34px;
margin: 18px 32px 22px;
    }
    .item{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
flex: 1;
line-height: 40px;
padding-bottom: 10px;
    }
    .act{
        color: #181818;
        position: relative;
    }
    .act::after{
        position: absolute;
        content: '';
        bottom: -1px;
        width: 48px;
height: 4px;
background: #009270;
left: 50%;
transform: translateX(-50%);
    }
   }
   .line{
    width: 100%;
    height: 16px;
background: #F7F7F7;
   }
    .stop{
        position: relative;
        width: 100%;
        height: 100px;
        .ico{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-50%);
        }
        .title{
            text-align: center;
            font-size: 32px;
font-weight: 400;
color: #333333;
line-height: 100px;
        }
    }
.content{
    height: calc(100vh - 200px);
    overflow-y: auto;
   .stopm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 28px;
    .stitle{
      font-size: 24px;
font-weight: 400;
color: #999999;
line-height: 34px;
    }
   }
    .item{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #F0F0F0;
        .left{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .nft{
                width: 100px ;
                height: 100px;
                margin: 0 20px;
                border-radius: 8px;
            }
            .title{
                font-size: 32px;
font-weight: 500;
color: #333333;
line-height: 44px;
text-align: left;
            }
            .time{
                font-size: 28px;
                text-align: left;
font-weight: 400;
color: #999999;
line-height: 40px;
            }
        }
        .right{
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }

        .sleft{
            display: flex;
            flex-direction: column;
            text-align: left;
            .title{
                font-size: 28px;
font-weight: 600;
color: #333333;
line-height: 40px;
            }
            .address{
                display: flex;
                align-items: center;
                font-size: 28px;
                .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
            }
        }
          .sright{
            display: flex;
            flex-direction: column;
            text-align: right;
        font-size: 24px;
font-weight: 400;
color: #999999;
        }
    }
}
 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
        width:50%;
    
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
  .bottomfilex{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .btn{
             width: 686px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
  }
}
</style>